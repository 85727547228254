<template>
  <li @click.stop="ontopclick(props.model)">
      <div class="titleicon">
          <span>{{ props.model.title_en }}</span>
          <img src="@/assets/enhomeicon.png" v-if="props.model.children && props.model.level != 1">
      </div>
      <div v-if="props.model.children" class="twomenu">
          <menuItems v-for="(item, index) in props.model.children" :model="item" :fun="props.fun" :key="index" />
      </div>
  </li>
</template>

<script>
export default {
  name: 'menuItems',
}
</script>
<script setup>
import { defineProps,defineEmits,ref} from 'vue';
const props = defineProps({
  model:[],
  fun:Function
})
const ontopclick = (e) => {
  props.fun(e)
}

</script>

<style lang="less" scoped>
@maxw: 128rem;
li{
  box-sizing: border-box;
  position: relative;
  padding: 0 20/@maxw 10/@maxw 20/@maxw;
  font-family: 'Microsoft YaHei';
  transition: ease .2s;
  >.titleicon{
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      font-size: 28/@maxw;
      >span{ transition: ease .2s }
      >img{
          width: 9/@maxw;
          height: 15/@maxw;
          vertical-align: middle;
          margin-right: 19/@maxw;
      }
  }
  >.twomenu{
      position: absolute;
      z-index: 9999999 !important;
      top: 53/@maxw;
      left:0;
      width: 300/@maxw;
      background-color: #174994;
      opacity: .95;
      transform:scale(0);
      transition: ease .2s;
       >li{
            transition: ease .1s;
            box-sizing: border-box;
            padding: 0 0 0 27/@maxw;
            margin: 0;
            width: 100%;
            height: 60/@maxw;
            line-height: 60/@maxw;
            color: #fff;
            font-family: 'Microsoft YaHei';
            >.titleicon span{
              width: 100%;
              height: 100%;
              font-size: 26/@maxw;
              overflow:hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow:ellipsis;
            }
            .twomenu{
              top: 0;
              left: 300/@maxw;
              transform:scale(0);
            }
       }
       >li:hover{
            background-image: url('../../assets/endactive.png');
            background-size: 100% 100%;
            color: #0D2F62;
            border: none !important;
            &:hover{
            >.titleicon{
              font-weight: 550;
            }
            >.twomenu{ transform:scale(1); }
          }
       }
  }
  &:last-child{ padding: 0 0 10/@maxw 20/@maxw; }
  &:first-child{ padding: 0 20/@maxw 10/@maxw 0; }
  &:hover{
      box-sizing: border-box;
      border-bottom: 5/@maxw solid #fff;
    .twomenu{ transform:scale(1); }
  }

}
</style>
