<template>
    <li @click.stop="props.funtwo(props.model)">
       <div class="titletext">
        <span>{{ props.model.title_en }}</span>
        <i v-if="props.model.children&&props.model.children.length>0" @click.stop="props.fun(props.ind,props.ind2)"> ＞</i>
       </div>
        <nav v-if="props.model.children&&props.model.children.length>0" class="nav">
          <menu-laen v-for="(item,i2) in props.model.children" 
          :key="item.id" 
          :model="item" 
          :ind="props.ind" 
          :ind2="i2" 
          :fun="props.fun"
          :funtwo="props.funtwo"
          >
          </menu-laen>
        </nav>
    </li>
</template>

<script>
export default {
    name: 'menuLaen',
}
</script>

<script setup>
import { defineProps,ref} from 'vue';
const props = defineProps({
    model:Object,
    ind:Number,
    ind2:Number,
    fun:Function,
    funtwo:Function
})
</script>

<style lang="less" scoped>
@maxw:50rem;
li{   
      width: 390/@maxw;
      min-height: 110/@maxw;
      line-height: 110/@maxw;
      border-bottom: 1px dotted #ccc;
      white-space: nowrap;
      .titletext{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      .nav{
        margin-left: 240/@maxw;
        line-height: 60/@maxw;
        padding-bottom: 15/@maxw;
        display: none;
      }
      &:last-child{
        border: none;
      }
    li{
      border:none !important;
      padding-left: 30/@maxw;
      line-height: 40/@maxw;
      white-space: pre-wrap;
      li{
        display: flex;
        align-items: center;
        min-height: 90/@maxw;
        line-height: 0 !important;
        &:last-child{
          padding-bottom: 30/@maxw;
        }
      }
    }
    }
    .block{display: block;}
    .none{display: none;}
</style>