<template>
  <span class="button" @click="onclickblock" :id="props.type=='lab'&&'labstyle'">☰</span>
  <div class="main" ref="main" :id="props.type=='lab'&&'mainstyle'">
    <ul ref="uls">
      <menulaen v-for="(item,i1) in props.list" :key="item.id" :model="item" :ind="i1" :fun="onclick" :funtwo="props.funone"></menulaen>
    </ul>
  </div>
</template>

<script setup>
import menulaen from '@/components/menulaen.vue'
import { reactive,ref,defineProps } from 'vue'
const data = reactive({
  list:[
    {title:"学院新闻",children:[{title:"联系我们"}]}, 
    {title:"学院新闻",children:[{title:"联系我们"},{title:'靓仔你好'}]},
    {title:'师资团队'},
    {title:'师资团队'},
    {title:'师资团队'},
    {title:'师资团队'},
    {title:'师资团队'},
  ]
})
const props = defineProps({
  list: [],
  type:String,
  funone:Function
})
const uls = ref(null)
const main = ref(null)
let flag1 = true
const onclickblock = () => {
  if(flag1){
    main.value.style['transform'] = 'scale(1)'
    flag1 = false
  }else{
    main.value.style['transform'] = 'scale(0)'
    flag1 = true
  }
  
}
let flag = true
const onclick = (i,i2,e) => {
  const nav = uls.value.children[i].children[1]
  if(i2>-1){
    const litwo = nav.children[i2].children[1]
    flag1?(litwo.className='block',flag1=false):(litwo.className='none',flag1=true)
  }else{
    flag?(nav.className='block',flag=false):(nav.className='none',flag=true)
  }
  
}
</script>
<style lang="less" scoped>
@maxw:50rem;
.button{
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 9999999;
  font-size: 50/@maxw;
}
.main{
  position: fixed;
  z-index: 9999999;
  transition: ease .3s;
  transform: scale(0);
  background-color: #174994;
  width: 500/@maxw;
  font-size: 18px;
  color: #D1BAA6;
  box-sizing: border-box;
  padding: 0 40/@maxw;
  height: 100vh;
  overflow: auto;
}
#labstyle{
  top: 0 !important;
}
#mainstyle{
  top: 80/@maxw;
}
@media screen and (min-width: 750px) { 
  .button{display: none !important;}
}
</style>    