<template>
  <div class="footerbox">
    <img :src="data.baseUrl+'uploads/images/0021a5a8c2227963581ed97f0fa7d8c2.png'" class="footerback">
    <div class="navbox">
      <div class="text1">
        <nav>中国科学院大学数字经济监测预测预警</nav>
        <nav>与政策仿真教育部哲学社会科学实验室（培育）</nav>
        <section>MOE Social Science Laboratory of Digital Economic Forecasts</section>
        <section>and Policy Simulation at UCAS</section>
      </div>
      <div class="text2">
        <section>常用链接</section>
        <nav v-for="item in data.link" @click="openr(item.jump_url)">{{ item.title_en }}</nav>
<!--        <nav @click="openr('https://www.las.ac.cn')">中国科学院</nav>-->
      </div>
      <div class="text3">
        <nav>Copyright © 2022 MOE Social Science Laboratory of Digital Economic Forecasts and Policy Simulation at UCAS. All rights reserved. </nav>
        <nav>© 2022 中国科学院大学数字经济监测预测预警与政策仿真教育部哲学社会科学实验室版权所有</nav>
      </div>
      <!-- <div class="text4"><img :src="data.baseUrl+'uploads/images/2106c28426560ee606ed226aaa5851f2.png'"></div> -->
    </div>
  </div>
</template>

<script setup>
import {ref,reactive,onMounted} from 'vue'
import { useRouter,useRoute } from 'vue-router'
import * as http from "@/api/API";
const data = reactive({
  baseUrl:process.env.VUE_APP_BASE_URL+'/',
  link:[]
})
onMounted( _ => {
  getLink();
})
//友情链接
const getLink = () => {
  http.getHttp("/api/article?page=1&limit=10&type_id=389&is_en=2")
      .then(res => {
        data.link = res.data.data.data;
        console.log('link',res.data.data.data)
      })
}
const openr = (e) => {
  window.open(e)
}
</script>

<style lang="less" scoped>
@maxw: 128rem;
.footerbox{
  position: relative;
  margin-top: 138/@maxw;
  width: 100%;
  height: 393/@maxw;
  overflow: hidden;
  .footerback{
    position: absolute;
    left: 0;
    top: 0;
    width:100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
  }
  .navbox{
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 1588/@maxw;
    height: 100%;
    color: #174994;
    font-family: 'Microsoft YaHei';
    .text1{
      position: absolute;
      left: 0;
      top: 60/@maxw;
      nav{font-size: 26/@maxw;font-weight: 550;}
      section{font-size: 18/@maxw;}
      section:first-of-type{margin-top: 28/@maxw;}
    }
    .text2{
      position: absolute;
      left: 822/@maxw;
      top: 60/@maxw;
      nav{ font-size: 14/@maxw;line-height: 25/@maxw;
        //float: left;
        //margin:0 1.8rem 0 0;
      }
      nav:hover{ font-weight: 800; }
      section{ 
        display: flex;
        justify-items: self-start;
        align-items: center;
        font-size: 26/@maxw;font-weight: 550;margin-bottom: 15/@maxw;
        &::after{
          margin-left: 10/@maxw;
          display: inline-block;
          content: '';
          width: 8/@maxw;
          height: 8/@maxw;
          border-bottom: 2/@maxw solid #174994;
          border-left: 2/@maxw solid #174994;
          transform: rotate(-45deg);
        }
      }
      
    }
    .text3{display: none;}
    // .text3{
    //   position: absolute;
    //   left: -85/@maxw;
    //   bottom:35/@maxw;
    //   color: #fff;
    //   font-size: 10px;
    //   line-height: 30/@maxw;
    //   transform: scale(0.8);
    // }
    // .text4{
    //   position: absolute;
    //   right:-16/@maxw;
    //   bottom:25/@maxw;
    //   width: 343/@maxw;
    //   height: 70/@maxw;
    //   img{width: 100%;height: 100%;vertical-align: middle;object-fit: cover;}
    // }
  }
}
@media screen and (max-width:750px) {
  @maxw:50rem;
  .footerbox{
  margin-top: 138/@maxw;
  height: 500/@maxw;
  .footerback{
    width:400%;
    height: 200%;
  }
  .navbox{
    width: 655/@maxw;
    .text1{
      top: 60/@maxw;
      nav{font-size: 26/@maxw;font-weight: 550;}
      section{font-size: 18/@maxw;}
      section:first-of-type{margin-top: 28/@maxw;}
    }
    .text2{
      // left: 822/@maxw;
      left: 0;
      top: 220/@maxw;
      nav{ font-size: 20/@maxw;line-height: 25/@maxw; }
      section{ 
        font-size: 26/@maxw;font-weight: 550;margin-bottom: 15/@maxw;
        &::after{
          margin-left: 10/@maxw;
          width: 8/@maxw;
          height: 8/@maxw;
        }
      }
      
    }
    .text3{
      position: absolute;
      bottom: 50/@maxw;
      left: 0;
      display: block;
      font-size: 10/@maxw !important;
    }
  }
}
}
</style>  