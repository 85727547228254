<template>
  <navm :list="data.navlist" :funone="functions" type="lab" />
  <div class="header">
    <div class="topimg" @click="pushroute('/moelab')"><img
        :src="data.baseUrl + 'uploads/images/660afed48f8a58e0f47f03d46ef5647f.png'" /></div>
    <div class="bottomli">
      <div class="search_" @click="search_botton">
        <span>
          <img src="@/assets/sousuo.png" alt="" @click="enSearch">
          <i><input v-model="data.keywords" @keydown="enter(2, $event)" placeholder="请输入关键字搜索" /></i>
        </span>
        <span>
          <img src="@/assets/menu.png" alt="">
          <div class="search_nav">
            <div class="top" @click="pushroute('/moelab')"><em class="span_">实验室首页</em></div>
            <div class="nav">
              <div class="nav_list_box">
                <span class="spans" v-for="item in data.navlist" :key="item.id"
                  @click="functions(item)">{{ item.title_en }}</span>
              </div>
              <div class="nav_list_boxTwo">
                <span class="spans" v-for="item in data.homenavs" :key="item.id"
                  @click="functions(item)">{{ item.title_en }}</span>
              </div>
            </div>
          </div>
        </span>
      </div>
      <ul>
        <menu-item v-for="(item, i1) in data.navlist" :key="item" :model="item" :fun="functions" />
      </ul>
    </div>
  </div>
</template>

<script setup>
import navm from '@/components/phonenavmenu.vue'
import menuItem from './menu.vue'
import { ref, onMounted, onUnmounted, reactive, defineProps } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import * as http from '@/api/API.js'
import { ElNotification } from 'element-plus'
import miiter from '@/utils/miitbus.js'
const router = useRouter()
const route = useRoute()
const data = reactive({
  baseUrl: process.env.VUE_APP_BASE_URL + '/',
  navlist: [],
  keywords: '',
  homenavs: [
    { title_en: '综合新闻', id: 385, tid: 385, level: 1, vue_url: 'Moearticlelist' },
    { title_en: '通知公告', id: 386, tid: 386, level: 1, vue_url: 'Moearticlelist' },
    { title_en: '讲座报告', id: 387, tid: 387, level: 1, vue_url: 'Moearticlelist' },
    { title_en: '科研动态', id: 388, tid: 388, level: 1, vue_url: 'Moearticlelist' },
  ]
})
const props = defineProps({
  isActive: Boolean
})
// 点击跳转
const pushroute = (e) => router.push(e)

// 全局事件总线滚动事件
const onScroll = () => {
  //滚动大于指定值时：
  miiter.on('laboratoryScroll', () => {
    if (route.path !== '/moelab') return
    // 判断页面元素是否为真或者是否渲染完毕，为真就给样式
    // document.querySelector(".topimg").style.background = 'linear-gradient(to bottom,#074096,#387ce253)'
    // document.querySelector(".bottomli").style.background = 'linear-gradient(to bottom,#074096,#387ce230)'
    document.querySelector(".header").id = 'headerbackg'
  })
  //滚动小于指定值时：
  miiter.on('unlaboratoryScroll', () => {
    if (route.path !== '/moelab') return
    // 判断页面元素是否为真或者是否渲染完毕，为真就给样式
    document.querySelector(".header").id = ''
  })
}
// 卸载
onUnmounted(() => {
  miiter.off('laboratoryScroll')
  miiter.off('unlaboratoryScroll')
})

// 如果isActive为真就直接加背景
const setactive = () => {
  if (!props.isActive) return
  document.querySelector(".header").id = 'headerbackg'
}

// 获取头部菜单栏
const getnav = () => {
  http.getNavlist({ is_en: 2, is_banner_nav: 1 }).then(res => {
    if (res.data.code == 200) {
      data.navlist = res.data.data
      console.log("🚀 ~ file: labheader.vue:94 ~ http.getNavlist ~ res.data:", res.data)
    }
  })
}
onMounted(_ => { onScroll(); setactive(); getnav() })
// 通过props传递函数,跳转对应
const functions = (e) => {
  if (e.id == 384) {
    router.push("/")
    return
  }
  // 如果有外部链接就跳转
  if (e.external_url) {
    window.open(e.external_url)
    return
  }
  if (e.template_type == 1) {
    router.push({
      name: e.children[0].vue_url,
      params: {
        id: e.children[0].id,
        tid: e.children[0].tid,
        level: e.children[0].level,
        isen: 2
      }
    })
    miiter.emit('onEnactilcetap')
    return
  }
  router.push({
    name: e.vue_url,
    params: {
      id: e.id,
      tid: e.tid,
      level: e.level,
      isen: 2
    }
  })
  miiter.emit('onEnactilcetap')
}
// 点击搜索
const enSearch = () => {
  if (data.keywords.trim()) {
    if (route.name == 'Moesearch') {
      window.sessionStorage.setItem("key", JSON.stringify(data.keywords))
      miiter.emit('enseachKeyword', data.keywords)
      return
    }
    router.push({
      name: 'Moesearch',
      params: {
        id: 361,
        tid: 360,
        level: 2,
        isen: 2
      }
    })
    window.sessionStorage.setItem("key", JSON.stringify(data.keywords))
    miiter.emit('enseachKeyword', data.keywords)
  } else {
    ElNotification({
      title: 'Warning',
      message: '搜索内容不能为空！',
      type: 'warning',
      zIndex: 9999999999999
    })
  }
}
// 监听回车事件
const enter = (n, e) => {
  if (n == 1) {
    enSearch()
    return
  }
  if (e.keyCode === 13) {
    enSearch()
  }
}
</script>

<style lang="less" scoped>
@maxw: 128rem;

.header {
  position: fixed;
  z-index: 99999;
  left: 50%;
  transform: translateX(-50%);
  width: 1920/@maxw;
  height: 205/@maxw;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  .topimg {
    width: 100%;
    height: 153/@maxw;
    color: #e2fcff00;
    transition: ease .3s;
    display: flex;
    justify-content: center;

    img {
      margin: 37/@maxw 0 41/@maxw 0;
      width: 1588/@maxw;
      height: 75/@maxw;
    }
  }

  .bottomli {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    transition: ease .3s;

    ul {
      cursor: pointer;
      width: 1588/@maxw;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;

      &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        width: 1588/@maxw;
        height: 1/@maxw;
        background: #fff;
      }
    }
  }
}

#headerbackg {
  background: url('../../assets/labtwobackg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.active {
  box-sizing: border-box;
  border-bottom: 5/@maxw solid #fff;
}

.search_ {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 205/@maxw;
  right: 0;
  width: 50/@maxw;
  height: 100/@maxw;
  z-index: 999999999;
  cursor: pointer;

  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50/@maxw;
    background-color: #174994de;

    img {
      width: 27/@maxw;
      height: 27/@maxw;
      transition: ease .5s;
    }

    img:hover {
      transform: rotate(90deg);
    }
  }

  >span:nth-child(2) {
    position: relative;
    background-color: #0D2F62;
  }

  >span:nth-child(1):hover {
    i {
      transform: scale(1);
    }
  }

  >span:nth-child(2):hover {
    .search_nav {
      transform: scale(1);
    }
  }

  i {
    position: absolute;
    top: 0;
    left: -408/@maxw;
    width: 408/@maxw;
    height: 50/@maxw;
    background-color: #65a3ffda;
    transform: scale(0);
    transition: ease .3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    >input {
      width: 100% !important;
      height: 100% !important;
      padding: 0 0 0 10/@maxw;
      margin: 0;
      outline: none;
      border: none;
      outline: medium;
      font-size: 22/@maxw;
      color: #9F9F9F;
    }

    >button {
      cursor: pointer;
      outline: none;
      width: 20%;
      height: 100%;
      border: none !important;
      background-color: #eee;
      color: #074096;
      font-size: 20/@maxw;
    }

    >button:hover {
      background-color: #ccc;
    }
  }
}

.search_nav {
  box-sizing: border-box;
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
  width: 458/@maxw;
  height: 696/@maxw;
  background: linear-gradient(to bottom, #103A7B, #1A4D9B, #195AB9);
  border-left: 4/@maxw solid #B19A86;
  opacity: 0.9;
  transform: scale(0);
  transition: ease .3s;
  font-family: "Microsoft YaHei";

  .top {
    width: 100%;
    height: 100/@maxw;
    border-bottom: 1px solid #d1baa693;
    font-size: 24/@maxw;
    color: #D1BAA6;
    display: flex;
    align-items: flex-end;

    >em {
      font-style: normal;
      background: none;
      margin: 0 0 22/@maxw 68/@maxw;
      transition: ease .3s;
    }

    >em:hover {
      color: #fff;
      font-weight: bold;
    }
  }

  .nav {
    position: relative;
    width: 150/@maxw;
    height: 85.5%;
    margin-left: 50/@maxw;
    border-left: 1/@maxw solid #d1baa693;
    border-right: 1/@maxw solid #d1baa693;
    display: flex;
    flex-direction: column;
  }

  .nav_list_box {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .spans {
    font-size: 20/@maxw;
    color: #D1BAA6;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    height: auto;
    margin-bottom: 21/@maxw;
    padding-left: 20/@maxw;
    background: none !important;
    transition: ease .3s;
    font-family: Source Han Sans CN-Medium;
  }

  .spans:first-child {
    margin-top: 25/@maxw;
  }

  .spans:hover {
    color: #fff;
    font-weight: bold;
  }

  .nav_list_boxTwo {
    width: 150%;
    position: absolute;
    top: 0;
    left: 152/@maxw;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width:750px) {
  @maxw: 50rem;

  .topimg {
    width: 100% !important;
    height: 45/@maxw !important;
    justify-content: start !important;

    img {
      margin: 22/@maxw 0 0 30/@maxw !important;
      width: 620/@maxw !important;
      height: 33/@maxw !important;
    }
  }

  .bottomli {
    display: none !important;
  }

  #headerbackg {
    background-image: none
  }
}</style>